.loading-suspense-spinner {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 10px solid #dbdbdbff;
	border-right-color: transparent;
	display: inline-block;
	animation: 0.75s linear 0s infinite normal both running spinner;
	background: transparent !important;

	&-container {
		height: 100vh;
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		background: rgba(255, 255, 255, 0.5);
	}
}
