.pdfWrapper {
	padding-top: 69px;
	width: 100%;

	.pdfViewer {
		width: 100%;

		.page {
			margin: 0;

			width: 100% !important;

			border: none;

			.canvasWrapper {
				width: 100% !important;
			}

			.textLayer {
				width: 100% !important;
			}
		}
	}
}
