.button {
	padding: 10.5px 21px;

	font-weight: bold;
	font-size: 14px;
	line-height: 100%;

	color: var(--color--white);

	background: #d0006e;
	border-radius: 30px;

	user-select: none;

	transition: background 0.15s;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: #c3086c;
	}

	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}

	&--light {
		color: var(--color--main-dark);

		background: var(--color--white);
		border: 1px solid #d9d8d8;

		&:hover {
			background: var(--color--white);
			border: 1px solid #b7b9bc;
		}
	}

	&--grey {
		color: var(--color--main-dark);

		background: #f8f8f8;

		&:hover {
			background: #f2f0f0;
		}
	}

	&--plain {
		color: var(--color--main-dark);

		background-color: transparent;

		&:hover {
			opacity: 0.9;
			background: transparent;
		}
	}

	&--back {
		img {
			margin-right: 8px;
		}
	}

	&--add {
		background: #009758;


		&:hover {
			background: #007846;
		}
	}

	&--delete {
		background: #E23729;


		&:hover {
			background: #bc2519;
		}
	}

	&.not-found {
		padding: 17px 45px;
	}
}
