:root {
	--color--white: #ffffff;
	--color--main-dark: #0b080f;
	--color--blue: #0052dd;
	--color--red: #dc6566;
	--color--dropdown-bg: #fdfdfd;
	--color--light-grey: #f8f8f8;
	--color--dark-tone: #242533;
	--color--main-gray: #f9f9f9;
	--color--pink100: #D0006E;
	--color--gray: #484964;
}
