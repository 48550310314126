* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Nunito Sans', sans-serif;
	color: var(--color--main-dark);
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

img {
	object-fit: contain;
}

button {
	cursor: pointer;
	border: none;
	user-select: none;
}

.none-media {
	opacity: 0;
	height: 1px;
	width: 1px;
	position: absolute;
	z-index: -99999;
	bottom: 0;
	display: none;
}

#file-input {
	display: none;
}
