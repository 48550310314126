@keyframes spinner {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.8);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}

@keyframes notification-show {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
