.toast {
    background: #484964;
    color: white;
    border-radius: 8px;
    padding: 16px 20px;

    &Body {
        flex: 1;
        padding: 0;
        margin: 0;
        div:last-child {
            flex-grow: 1;
            color: white;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
    }
}